@media (min-width: 811px){
    .imgMob {
        display: none;
    }

}

@media (max-width: 811px) {
    .imgDesk {
        display: none;
    }
}

.parag{
    max-width: 25ch;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

.break{
    word-break: break-all;
}

.custom-img-size {
    width: 100%; /* Mantém a largura total da tela */
    height: 80vh; /* Ajusta a altura para 50% da altura da tela, você pode mudar isso conforme necessário */
}
